@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    @apply bg-primary-bg scroll-smooth text-primary-text;
    padding: 0;
    margin: 0;
  }

  .root {
    @apply flex w-screen flex-col items-center;
  }
}
